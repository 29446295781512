import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import * as Dialog from '@radix-ui/react-dialog';

const Clients = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ClientDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Container = styled.div`
  max-width: 850px;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 200;
  inset: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
`;

const Content = styled(Dialog.Content)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  margin-top: 118px;
  width: 834px;
  height: auto;
  padding: 45px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentFooter = styled.div`
  padding-top: 24px;
  display: flex;
  width: 100%;
  gap: 16px;
`;

const CloseButton = styled(Dialog.Close)`
  cursor: pointer;
`;
export const S = {
  ...UIElements,
  Container,
  Clients,
  ClientDescription,
  Button,
  Overlay,
  Content,
  ContentHeader,
  ContentFooter,
  CloseButton,
};
