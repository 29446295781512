import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

const Button = styled(UIElements.Button)`
  height: auto;
`;


const TBodyCell = styled.td`
  vertical-align: middle;
  padding: 16px;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
`;

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 200;
  inset: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
`;

const Content = styled(Dialog.Content)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  margin-top: 118px;
  width: 834px;
  height: auto;
  padding: 45px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentFooter = styled.div`
  padding-top: 24px;
  display: flex;
  width: 100%;
  gap: 16px;
`;

const CloseButton = styled(Dialog.Close)`
  cursor: pointer;
`;

const FieldSet = styled.fieldset`
  padding: 24px 0;
  border: none;
`;

const Grid = styled.div<{ rows: number }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  padding-top: 10px;
`;

const FlexView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 35px;
  grid-row-gap: 0px;
  height: 183px;
  padding: 10px 0px;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const SmallText = styled(UIElements.B3)`
  margin-bottom: 7px;
`;

const Image = styled.img`
  height: 154px;
  width: 123px;
  border-radius: 2px;
  overflow: hidden;
  object-fit: cover;
`;

const DeleteButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  bottom: 142px;
  right: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

const TextInput = styled.textarea`
  height: 154px;
  width: 100%;
  resize: none;
  padding: 7px 10px;
  border-radius: 2px;
  background-color: #fbfbfb;
`;

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HiddenInput = styled.input`
  display: none;
`;



export const S = {
  ...UIElements, Button, HiddenInput,
  Overlay,
  TBodyCell,
  Content,
  ContentHeader,
  ContentFooter,
  CloseButton,
  FieldSet,
  Grid,
  FlexView,
  ImageContainer,
  RelativeContainer,
  SmallText,
  Image,
  DeleteButton,
  TextInput,
  TextInputWrapper,
};
