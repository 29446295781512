import React from 'react';
import { FindAllFrameCompaniesResponse } from '@ansvar-sakerhet/api-client';
import { useTable } from 'react-table';
import { S } from './FrameCompaniesTable.style';
import { useFrameCompaniesTableData } from './hooks/useFrameCompaniesTableData';
import * as Dialog from '@radix-ui/react-dialog';
import { useDeleteBackgroundControl } from '../../../../../../../../../../../traits/api/backgroundControls/useDeleteBackgroundControl';


export const FrameCompaniesTable = ({
  frameCompanies,
}: {
  frameCompanies: FindAllFrameCompaniesResponse[];
}) => {
  const data = useFrameCompaniesTableData(frameCompanies);
  const table = useTable(data);
  const [open, setOpen] = React.useState<null | string>();
  const deleteBgControl = useDeleteBackgroundControl();

  if (frameCompanies.length === 0) {
    return <S.B1>Det finns inga avtalsföretag att visa.</S.B1>;
  }

  return (
    <>

      <S.TableContainer>

        <S.AltTable {...table.getTableProps()}>
          <S.AltTHead>
            {table.headerGroups.map((headerGroup) => (
              <S.AltTHeadRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <S.AltTHeadCell
                      {...column.getHeaderProps()}
                      key={column.getHeaderProps().key}
                    >
                      <S.TableText>{column.render('Header')}</S.TableText>
                    </S.AltTHeadCell>
                  );
                })}
              </S.AltTHeadRow>
            ))}
          </S.AltTHead>

          <S.AltTBody {...table.getTableBodyProps()}>
            {table.rows.map((row) => {
              table.prepareRow(row);

              return (
                <>
                  <S.AltTBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                    {row.cells.map((cell) => {
                      if (cell.column.id === 'name') {
                        return (
                          <>
                            <S.AltTBodyCell
                              onClick={() => {
                                if (open === row.id) {
                                  setOpen(null);
                                } else {
                                  setOpen(row.id);
                                }
                              }}
                              {...cell.getCellProps()}
                              key={cell.getCellProps().key}
                            >
                              {cell.render('Cell')}
                            </S.AltTBodyCell>

                          </>
                        );


                      }
                      return (
                        <S.AltTBodyCell
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                        >
                          {cell.render('Cell')}
                        </S.AltTBodyCell>
                      );
                    })}
                  </S.AltTBodyRow>
                </>
              );
            })}
          </S.AltTBody>
        </S.AltTable>
      </S.TableContainer>
      <Dialog.Root open={open !== undefined && open !== null}>
        <Dialog.Portal>
          <S.Overlay>
            <S.Content>
              <S.ContentHeader>
                <Dialog.Title asChild>
                  <S.H4>Tidigare bakgrundskontroller</S.H4>
                </Dialog.Title>

                <S.CloseButton onClick={() => setOpen(null)}>
                  <S.Icon src="Close" width={20} height={20} />
                </S.CloseButton>
              </S.ContentHeader>
              {
                open && frameCompanies[Number(open)] ? frameCompanies[Number(open)].BackgroundControl.map((control) => {
                  return (
                    <S.Flex key={control.backgroundControlId}>
                      <S.Margin />
                      <S.B1>{(new Date(control.createdAt).toISOString().split('T')[0])}</S.B1>
                      <S.CloseButton onClick={() => deleteBgControl.mutate(control.backgroundControlId)}>
                        <S.Icon src="Close" width={10} height={10} />
                      </S.CloseButton>
                    </S.Flex>
                  );
                }) : null
              }
            </S.Content>
          </S.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
