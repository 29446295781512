import React, { useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { RadioInput } from '../../../../../../../../../../../controls/screens/CompaniesData/components/RadioInput';
import { S } from './CreateBGControl.styles';
import { CreateBackgroundControlFormValues, useCreateBackgroundControlForm } from '../../../../../../../../../../../controls/screens/CompaniesData/hooks/useCreateBackgroundControlForm';
import { useReportUpload } from '../../../../../../../../../../../controls/screens/CompaniesData/components/CreateBackgroundControlModal/hooks/useReportUpload';
import { toast } from '../../../../../../../../../../../../components/Toast';
import { BackgroundControlWithFilesRiskEnum, FindAllMyProjectsResponse } from '@ansvar-sakerhet/api-client';
import { useCreateBackgroundControl } from '../../../../../../../../../../../../traits/api/backgroundControls/useCreateBackgroundControl';
import { useGetAllClients } from '../../../../../../../../../../../../traits/api/clients/useGetAllClients';
import { Select } from '@ansvar-sakerhet/ui/src/selects/Select';
import { useGetAllProjects } from '../../../../../../../../../../../../traits/api/projects/useGetAllProjects';
import { Input } from '../../../../../../../../../../../../components/Input';

type Props = {
  open: boolean;
  onCancel: () => void;
  clientId: string | null;
  projectId: string | null;
  companyReg: string | null;
};

export const CreateBGControl: React.FC<Props> = (props) => {
  const { handleSubmit, watch, register, control, setValue, getValues, reset } =
    useCreateBackgroundControlForm();
  const { mutateAsync: createBackgroundControl } =
    useCreateBackgroundControl();
  const { handleUploadClick, inputRef, handleFileChange } = useReportUpload();

  const clients = useGetAllClients();
  const {getProjects} = useGetAllProjects({});
  const [selectedClient, setSelectedClient] = React.useState<string | null>(null);
  const [projects, setProjects] = React.useState<FindAllMyProjectsResponse[] | null>(null);
  const [projectId, setProjectId] = React.useState<string | null>(null);
  const [companyReg, setCompanyReg] = React.useState<string>('');
  const [companyName, setCompanyName] = React.useState<string>('');
  const [date, setDate] = React.useState<string>('2025-01-01');
  const [lastDate, setLastDate] = React.useState<string>('2025-01-01');
  
  useEffect(() => {
    if (props.clientId) {
      setSelectedClient(props.clientId);
    }

    if(props.projectId){
      setProjectId(props.projectId);
    }
  }, [props.clientId, props.projectId]);

  useEffect(() => {
    if(props.companyReg){
      setCompanyReg(props.companyReg);
    }
  }, [props.companyReg]);

  useEffect(() => {
    if (selectedClient) {
      getProjects(selectedClient).then((projects) => {
        setProjects(projects);
        setProjectId(props.projectId ? props.projectId : projects[0].projectId);
      });
    }
  }, [selectedClient]);
  const onErrors = () => {
    toast.error('Formuläret är inte korrekt ifyllt');
  };

  const handleSave = async (data: CreateBackgroundControlFormValues) => {
    try {
      await createBackgroundControl({
        fileId: data.fileId ?? '',
        risk: data.backgroundControlStatus,
        optedOut: data.optedOut === 'yes',
        companyRegistrationNumber: companyReg,
        projectId: projectId ?? '',
        orgName: companyName,
        date: date,
        lastDate: lastDate,
      });
      setCompanyReg('');
      setCompanyName('');
      setDate('2025-01-01');
      setLastDate('2025-01-01');
      reset();

      toast.success('Ändringarna har sparats');
      props.onCancel();
    } catch (e) {
      toast.error('Något gick fel, gick inte att spara ändringarna');
    }
  };

  return (
    <Dialog.Root open={props.open}>
      <Dialog.Portal>
        <S.Overlay>
          <S.Content>
            <S.ContentHeader>
              <Dialog.Title asChild>
                <S.H4>Ladda upp bakgrundskontroll</S.H4>
              </Dialog.Title>

              <S.CloseButton asChild onClick={props.onCancel}>
                <S.Icon src="Close" width={20} height={20} />
              </S.CloseButton>
            </S.ContentHeader>

            <form onSubmit={handleSubmit(handleSave, onErrors)}>
              <S.FieldSet>
                <S.H5>Välj kund och projekt</S.H5>
                <S.Margin />
                <Select
                  name="client"
                  onChange={(ev) => setSelectedClient(ev.target.value)}
                  value={selectedClient ?? ''}
                >
                  {clients.data?.map((client) => (
                    <option key={client.clientId} value={client.clientId}>
                      {client.name}
                    </option>
                  ))}
                </Select>
                <S.Margin />
                <Select
                onChange={(v) => {
                  setProjectId(v.target.value);
                }}
                  name="project"
                  value={projectId ?? ''}
                >
                  {
                    projects ? projects.map((project) => (
                      <option key={project.projectId} value={project.projectId}>
                        {project.name}
                      </option>
                    )) : null
                  }
                </Select>
                <S.Margin />
                <Input
                  value={companyReg}
                  onChange={(ev) => setCompanyReg(ev.target.value)}
                  label="Org. nummer"
                />
                <Input
                  value={companyName}
                  onChange={(ev) => setCompanyName(ev.target.value)}
                  label="Företagsnamn (om det inte redan finns ett företag)"
                />
                <Input 
                  type='date'
                  label='Datum för bakgrundskontroll'
                  onChange={(ev) => {
                    setDate(ev.target.value)
                  }}
                  value={date}
                  />
                <Input 
                  type='date'
                  label='Utgångsdatum för bakgrundskontroll'
                  onChange={(ev) => {
                    setLastDate(ev.target.value)
                  }}
                  value={lastDate}
                  />
                <RadioInput
                  {...register('backgroundControlStatus')}
                  control={control}
                  state={watch('backgroundControlStatus')}
                  question="Status för bakgrundskontroll"
                  name="backgroundControlStatus"
                  options={[
                    {
                      label: 'Inga risker identifierade',
                      value: BackgroundControlWithFilesRiskEnum.NoRisk,
                    },
                    {
                      label: 'Mindre väsentliga risker',
                      value: BackgroundControlWithFilesRiskEnum.LesserRisk,
                    },
                    {
                      label: 'Medel-hög risk',
                      value: BackgroundControlWithFilesRiskEnum.MediumHighRisk,
                    },
                    {
                      label: 'Hög risk',
                      value: BackgroundControlWithFilesRiskEnum.HighRisk,
                    },
                  ]}
                />

                <RadioInput
                  {...register('optedOut')}
                  control={control}
                  state={watch('optedOut')}
                  question="Bortvald"
                  name="optedOut"
                  options={[
                    {
                      label: 'Nej',
                      value: 'no',
                    },
                    {
                      label: 'Ja',
                      value: 'yes',
                    },
                  ]}
                />

                <S.FlexView>
                  <S.H6>Rapport</S.H6>
                  <S.Button type="button" onClick={handleUploadClick}>
                    Ladda upp rapport
                  </S.Button>
                  {getValues().fileId && 'Fil är uppladdad'}
                  <S.HiddenInput
                    type="file"
                    ref={inputRef}
                    onChange={async (ev) => {
                      handleFileChange(ev).then((fileId) =>
                        setValue('fileId', fileId ?? '')
                      );
                    }}
                    accept=".pdf"
                  />
                </S.FlexView>
              </S.FieldSet>

              <S.ContentFooter>
                <Dialog.Close asChild onClick={props.onCancel}>
                  <S.Button $outlined>Avbryt</S.Button>
                </Dialog.Close>

                <S.Button type="submit">Spara</S.Button>
              </S.ContentFooter>
            </form>
          </S.Content>
        </S.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
