import styled from 'styled-components';

export const Select = styled.select`
  width: 100%;
  padding: 1rem;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
    appearance: none;

`;
