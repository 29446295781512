import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

// TODO: Put the table components into the UI package as an alternative table

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  flex-grow: 1;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const DeleteDataCell = styled.td`
  vertical-align: middle;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  min-width: 210px;
`;
const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 200;
  inset: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
`;

const CenteredIcon = styled(UIElements.Icon)`
  vertical-align: middle;
`;

const TableText = styled.p`
  display: inline;
`;

const Content = styled(Dialog.Content)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  margin-top: 118px;
  width: 834px;
  height: auto;
  padding: 45px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CloseButton = styled(Dialog.Close)`
  cursor: pointer;
  margin-left: 16px;
`;

export const S = {
  ...UIElements,
  TableText,
  CenteredIcon,
  Overlay,
  Content,
  ContentHeader,
  CloseButton,
  TableContainer,
  PaginationContainer,
  DeleteDataCell,
};
