
import { useApi } from '../useApi';

type Props = {
  take?: number | undefined;
  skip?: number | undefined;
};

export function useGetAllProjects(props: Props) {
  const getApi = useApi();
  

  const getProjects = async (clientId: string) => {
    const api = await getApi();

    const res = await api.projectsFindClientProjects(
      clientId,
      props?.take,
      props?.skip
    );
    return res.data;
  };



  return { getProjects };
}
