import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const addRedListedCompany = z.object({
  name: z.string().min(1, 'Namn måste anges'),
  registrationNumber: z
    .string()
    .refine((val) => val.length > 0, { message: 'Org.nr måste anges' })
    .refine((val) => /^[0-9]+$/.test(val), {
      message: 'Org.nr måste endast bestå av siffror',
    }),
    date: z.string(),
});

export type addCustomerUserFormValues = z.infer<typeof addRedListedCompany>;

export const useAddRedListedCompanyForm = () => {
  const methods = useForm<addCustomerUserFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(addRedListedCompany),
    defaultValues: {
      name: '',
      registrationNumber: '',
      date: new Date().toISOString(),
    },
  });

  return methods;
};
