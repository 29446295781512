import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { RadioInput } from '../../../../../controls/screens/CompaniesData/components/RadioInput';
import { S } from './CreateBackgroundControlModal.style';
import {
  CreateBackgroundControlFormValues,
  useCreateBackgroundControlForm,
} from '../../../../../controls/screens/CompaniesData/hooks/useCreateBackgroundControlForm';
import { useCreateBackgroundControlFrame } from '../../../../../../traits/api/backgroundControls/useCreateBackgroundControlFrame';
import { useReportUpload } from '../../../../../controls/screens/CompaniesData/components/CreateBackgroundControlModal/hooks/useReportUpload';
import { toast } from '../../../../../../components/Toast';
import { BackgroundControlWithFilesRiskEnum } from '@ansvar-sakerhet/api-client';
import { Input } from '../../../../../../components/Input';

type Props = {
  frameCompanyId: string;
  open: boolean;
  onCancel: () => void;
};

export const CreateBackgroundControlModal: React.FC<Props> = (props) => {
  const { handleSubmit, watch, register, control, setValue, getValues } =
    useCreateBackgroundControlForm();
  const { mutateAsync: createBackgroundControl } =
    useCreateBackgroundControlFrame();
  const { handleUploadClick, inputRef, handleFileChange } = useReportUpload();
  const [date, setDate] = React.useState<string>('2025-01-01');

  const onErrors = () => {
    toast.error('Formuläret är inte korrekt ifyllt');
  };

  const handleSave = async (data: CreateBackgroundControlFormValues) => {
    try {
      await createBackgroundControl({
        fileId: data.fileId ?? '',
        risk: data.backgroundControlStatus,
        optedOut: data.optedOut === 'yes',
        frameCompanyId: props.frameCompanyId,
        date: date,
      });
      toast.success('Ändringarna har sparats');
      props.onCancel();
    } catch (e) {
      toast.error('Något gick fel, gick inte att spara ändringarna');
    }
  };

  return (
    <Dialog.Root open={props.open}>
      <Dialog.Portal>
        <S.Overlay>
          <S.Content>
            <S.ContentHeader>
              <Dialog.Title asChild>
                <S.H4>Ladda upp bakgrundskontroll</S.H4>
              </Dialog.Title>

              <S.CloseButton asChild onClick={props.onCancel}>
                <S.Icon src="Close" width={20} height={20} />
              </S.CloseButton>
            </S.ContentHeader>

            <form onSubmit={handleSubmit(handleSave, onErrors)}>
              <S.FieldSet>
                <S.H5>Frågeformulär</S.H5>

                <RadioInput
                  {...register('backgroundControlStatus')}
                  control={control}
                  state={watch('backgroundControlStatus')}
                  question="Status för bakgrundskontroll"
                  name="backgroundControlStatus"
                  options={[
                    {
                      label: 'Inga risker identifierade',
                      value: BackgroundControlWithFilesRiskEnum.NoRisk,
                    },
                    {
                      label: 'Mindre väsentliga risker',
                      value: BackgroundControlWithFilesRiskEnum.LesserRisk,
                    },
                    {
                      label: 'Medel-hög risk',
                      value: BackgroundControlWithFilesRiskEnum.MediumHighRisk,
                    },
                    {
                      label: 'Hög risk',
                      value: BackgroundControlWithFilesRiskEnum.HighRisk,
                    },
                  ]}
                />
                <Input
                  type='date'
                  label='Datum för bakgrundskontroll'
                  value={date}
                  onChange={(ev) => setDate(ev.target.value)}
                />


                <RadioInput
                  {...register('optedOut')}
                  control={control}
                  state={watch('optedOut')}
                  question="Bortvald"
                  name="optedOut"
                  options={[
                    {
                      label: 'Nej',
                      value: 'no',
                    },
                    {
                      label: 'Ja',
                      value: 'yes',
                    },
                  ]}
                />

                <S.FlexView>
                  <S.H6>Rapport</S.H6>
                  <S.Button type="button" onClick={handleUploadClick}>
                    Ladda upp rapport
                  </S.Button>
                  {getValues().fileId && 'Fil är uppladdad'}
                  <S.HiddenInput
                    type="file"
                    ref={inputRef}
                    onChange={async (ev) => {
                      handleFileChange(ev).then((fileId) =>
                        setValue('fileId', fileId ?? '')
                      );
                    }}
                    accept=".pdf"
                  />
                </S.FlexView>
              </S.FieldSet>

              <S.ContentFooter>
                <Dialog.Close asChild onClick={props.onCancel}>
                  <S.Button $outlined>Avbryt</S.Button>
                </Dialog.Close>

                <S.Button type="submit">Spara</S.Button>
              </S.ContentFooter>
            </form>
          </S.Content>
        </S.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
