import { useApi } from '../useApi';

export function useGetCsv(personell: boolean, controlId: string) {
  const getApi = useApi();

  const getCsv = async () => {
    const api = await getApi();
    if(personell) {
        const res = await api.controlsGetPersonellCsv(controlId);
        return res.data + "";
    } else {
        const res = await api.controlsGetCompanyCsv(controlId);
        return res.data + "";
    }
    
  };


  return getCsv;
}
