import {
  BackgroundControlOrder,
  FindAllCompaniesResponse,
} from '@ansvar-sakerhet/api-client';

export function findLatestBackgroundControlOrder(
  company: FindAllCompaniesResponse
): BackgroundControlOrder | undefined {
  return company.BackgroundControlOrder.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];
}
