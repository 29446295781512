import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { keys } from './company.key';

type Options = {
  onDeleteFileSuccess?: () => void;
  onDeleteFileError?: (error: unknown) => void;
};

export function useDeleteCompany(options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const deleteFile = async ({ companyId }: { companyId: string }) => {
    const api = await getApi();

    const res = await api.companiesDelete(companyId);
    return res.data;
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: keys.all
    });
    options?.onDeleteFileSuccess?.();
  };

  const onError = (error: unknown) => {
    toast.error('Fel, kunde inte radera bolag');

    options?.onDeleteFileError?.(error);
  };

  const mutation = useMutation(deleteFile, {
    onSuccess,
    onError,
  });

  return mutation;
}
