import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { S } from './AddRedListedModal.style';
import { AddRedListedCompanyForm } from '../AddRedListedForm/AddRedListedCompanyForm';

type Props = {
  open: boolean;
  onCancel: () => void;
};

export const AddRedListedModal: React.FC<Props> = (props) => {
  if (!props.open) {
    return <></>;
  }

  return (
    <Dialog.Root open={props.open}>
      <Dialog.Portal>
        <S.Overlay>
          <S.Content>
            <S.ContentHeader>
              <Dialog.Title asChild>
                <S.H4>Skapa nytt rödlistat bolag</S.H4>
              </Dialog.Title>

              <S.CloseButton asChild onClick={props.onCancel}>
                <S.Icon src="Close" width={20} height={20} />
              </S.CloseButton>
            </S.ContentHeader>

            <AddRedListedCompanyForm onCancel={props.onCancel} />
          </S.Content>
        </S.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
