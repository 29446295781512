import React, { useState } from 'react';
import { S } from './SingleClientLayout.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetClient } from '../../../../../../traits/api/clients/useGetClient';
import { CustomerUsersList } from './layouts/CustomerUsers/CustomerUsersList';
import { FrameCompaniesList } from './layouts/FrameCompanies/FrameCompaniesList/FrameCompaniesList';
import { CustomerInfo } from './layouts/CustomerInfo/CustomerInfo';
import { Client } from '@ansvar-sakerhet/api-client';
import { Loading } from '../../../../../../components/Loading';
import { SubContractorsList } from './layouts/Subcontractors/SubcontractorsList';
import { DangerZone } from './layouts/DangerZone/DangerZone';

enum Flows {
  CustomerInfo,
  FrameCompanies,
  SubContractors,
  CustomerUsers,
  DangerZone

}

export const SingleClientLayout = () => {
  const [currentFlow, setCurrentFlow] = useState<Flows>(Flows.CustomerInfo);
  const { clientId } = useParams();
  const { data: client, isLoading: clientLoading } = useGetClient(clientId);
  const navigate = useNavigate();

  if (clientLoading || !client) {
    return (
      <S.HeaderContainer>
        <Loading />
      </S.HeaderContainer>
    );
  }

  return (
    <React.Fragment>
      <S.HeaderContainer>
        <S.HeaderInner>
          <S.InfoContainer>
            <S.BackIcon
              width={36}
              height={36}
              onClick={() => navigate(-1)}
              src="ChevronLeft"
            />
            <S.H2 $weight={600}>Kund {client.name}</S.H2>
          </S.InfoContainer>

          <S.NavigationContainer>
            <S.NavLink
              $isActive={currentFlow === Flows.CustomerInfo}
              onClick={() => setCurrentFlow(Flows.CustomerInfo)}
            >
              Kund-info
            </S.NavLink>

            <S.NavLink
              $isActive={currentFlow === Flows.FrameCompanies}
              onClick={() => setCurrentFlow(Flows.FrameCompanies)}
            >
              Avtalsföretag
            </S.NavLink>

            <S.NavLink
              $isActive={currentFlow === Flows.SubContractors}
              onClick={() => setCurrentFlow(Flows.SubContractors)}
            >
              Underentreprenörer
            </S.NavLink>

            <S.NavLink
              $isActive={currentFlow === Flows.CustomerUsers}
              onClick={() => setCurrentFlow(Flows.CustomerUsers)}
            >
              Kund-användare
            </S.NavLink>
            <S.NavLink
              $isActive={currentFlow === Flows.DangerZone}
              onClick={() => setCurrentFlow(Flows.DangerZone)}
            >
              Farlig zon
            </S.NavLink>
          </S.NavigationContainer>
        </S.HeaderInner>
      </S.HeaderContainer>

      <S.BodyContainer>
        <S.BodyInner>
          <DynamicFlow clientId={clientId} client={client} flow={currentFlow} />
        </S.BodyInner>
      </S.BodyContainer>
    </React.Fragment>
  );
};

const DynamicFlow = ({
  flow,
  clientId,
  client,
}: {
  flow: Flows;
  clientId: string;
  client: Client;
}) => {
  switch (flow) {
    case Flows.CustomerUsers:
      return <CustomerUsersList clientId={clientId} />;
    case Flows.FrameCompanies:
      return <FrameCompaniesList clientId={clientId} />;
    case Flows.CustomerInfo:
      return <CustomerInfo client={client} />;
    case Flows.SubContractors:
      return <SubContractorsList clientId={clientId} />;
    case Flows.DangerZone:
      return <DangerZone clientId={clientId} />;
  }
};
