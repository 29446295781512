import React, { useMemo } from 'react';
import {
  BackgroundControlFileWithFile,
  BackgroundControlOrder,
  BackgroundControlWithFiles,
  BackgroundControlWithFilesRiskEnum,
  FindAllCompaniesResponse,
} from '@ansvar-sakerhet/api-client';
import { findLatestBackgroundControl } from '../utils/findLatestBackgroundControl';
import { findLatestBackgroundControlOrder } from '../utils/findLatestBackgroundControlOrder';
import { DownloadReportButton } from '../../DownloadReportButton';
import { findLatestReport } from '../utils/findLatestReport';

export const useCompaniesTableData = (
  companies: FindAllCompaniesResponse[]
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'name',
      },
      {
        Header: 'Org.nr',
        accessor: 'registrationNumber',
      },
      {
        Header: 'Kontroll beställd',
        accessor: 'controlOrderedAt',
      },
      {
        Header: 'Senaste bakgrundskontroll',
        accessor: 'latestBackgroundControl',
      },
      {
        Header: 'Utgångsdatum',
        accessor: 'lastDate',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return companies.map((company) => {
      const latestControlOrder = findLatestBackgroundControlOrder(company);
      const latestControl = findLatestBackgroundControl(company);
      const report = latestControl
        ? findLatestReport(latestControl)
        : undefined;

      return {
        name: company.name,
        registrationNumber: company.registrationNumber,
        controlOrderedAt: controlOrderedAt(latestControlOrder),
        latestBackgroundControl: latestBackgroundControl(latestControl),
        latestReport: latestReport(report),
        lastDate: latestControl && latestControl.lastDate ? latestControl.lastDate.split('T')[0] : '-',
        createBackgroundControl: (
          // <CreateBackgroundControlButton
          //   frameCompanyId={company.frameCompanyId}
          // />
           <div>
                  {/* <Dialog.Root >
                    <CreateBackgroundControlModal
                      open={false}
                      companyRegistrationNumber={company.registrationNumber}
                      onCancel={() => {
                        console.log("cancel")
                      }}
                    />
                  </Dialog.Root> */}
                </div>
        ),
      };
    });
  }, [companies]);

  return { columns, data };
};

function controlOrderedAt(controlOrder: BackgroundControlOrder | undefined) {
  if (!controlOrder) return '-';

  const potentialDate = controlOrder.createdAt;
  const dateStr = potentialDate
    ? new Date(potentialDate).toLocaleDateString()
    : '-';

  return dateStr;
}

const AcceptanceTranslation: Record<
  BackgroundControlWithFilesRiskEnum,
  string
> = {
  NoRisk: 'Inga risker identifierade',
  LesserRisk: 'Avvikelser identifierade, OK att samarbeta',
  MediumHighRisk: 'Avvikelser identifierade, åtgärder föreslås',
  HighRisk:
    'Väsentliga risker Avrådan från att anlita företaget eller åtgärda avvikelser',
};

function latestBackgroundControl(
  control: BackgroundControlWithFiles | undefined
) {
  if (!control) return '-';

  const result = AcceptanceTranslation[control.risk];
  const controlDate = new Date(control.createdAt);
  const resText = `${result} - ${controlDate.toLocaleDateString()}`;

  return resText;
}

function latestReport(report: BackgroundControlFileWithFile | undefined) {
  if (!report) return '-';

  return <DownloadReportButton downloadUrl={report.file.url} />;
}
