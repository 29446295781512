import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateFaqResponse } from '@ansvar-sakerhet/api-client';
import { useApi } from '../useApi';
import { controlKeys } from './controls.key';
import { toast } from '../../../components/Toast';

type Options = {
  onCreateQuestionSuccess?: (data: CreateFaqResponse) => void;
  onCreateQuestionError?: (error: unknown) => void;
};

export function archiveControl(options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const archiveControlDo = async (controlId: string) => {
    const api = await getApi();

    const res = await api.controlsArchiveControl(controlId);

    return res.data;
  };

  const onSuccess = () => {
    toast.success('Kontrollen har arkiverats');
    queryClient.invalidateQueries({ queryKey: controlKeys.all });
  };

  const onError = (error: unknown) => {
    toast.error('Något gick fel');

    options?.onCreateQuestionError?.(error);
  };

  const mutation = useMutation(archiveControlDo, {
    onSuccess,
    onError,
  });

  return mutation;
}
