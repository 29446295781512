import {
  BackgroundControlWithFiles,
  FindAllCompaniesResponse,
} from '@ansvar-sakerhet/api-client';

export function findLatestBackgroundControl(
  company: FindAllCompaniesResponse
): BackgroundControlWithFiles | undefined {
  return company.backgroundControls.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];
}
