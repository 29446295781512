import React from 'react';
import { S } from './DownloadReportButton.styles';
import { toast } from '../../../../../../../../../../components/Toast';

export const DownloadReportButton = ({
  downloadUrl,
}: {
  downloadUrl: string;
}) => {
  const startDownload = () => {
    if (!downloadUrl) {
      return toast.error('Fil kunde inte hittas');
    }

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'rapport';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <S.DownloadButton onClick={startDownload}>
      <S.IconWithBg src="Draft" /> Ladda ner rapport
    </S.DownloadButton>
  );
};
