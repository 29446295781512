import React, { useState } from 'react';
import { S } from './Clients.style';
import { useGetAllClients } from '../../../../traits/api/clients/useGetAllClients';
import { Loading } from '../../../../components/Loading';
import { ClientsTable } from './components/ClientsTable';
import * as Dialog from '@radix-ui/react-dialog';
import { Input } from '../../../../components/Input';
import { useCreateClient } from '../../../../traits/api/clients/createClient';

export const Clients: React.FC = () => {
  const clientsPerPage = 20;
  const [page, setPage] = useState(0);
  const [newClient, setNewClient] = useState(false);
  const [clientName, setClientName] = useState('');
  const createClientReq = useCreateClient();

  const clients = useGetAllClients({
    take: clientsPerPage,
    skip: page * clientsPerPage,
  });


  const createClient = () => {
    createClientReq.mutateAsync({ name: clientName });
  }

  if (clients.isLoading || !clients.data) {
    return (
      <S.Container>
        <S.H3>Kunder</S.H3>
        {/* <S.Button onClick={() => navigate('/ny-kund')}>Ny kund</S.Button> */}
        <S.Margin $top="m" />
        <S.Clients>
          <Loading />
        </S.Clients>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.Flex style={{ justifyContent: 'space-between' }}>
        <S.H3>Kunder</S.H3>
        <S.Button onClick={() => {
          setNewClient(true);
        }}>Ny kund</S.Button>
      </S.Flex>
      <Dialog.Root open={newClient}>
        <Dialog.Portal>
          <S.Overlay>
            <S.Content>
              <S.ContentHeader>
                <Dialog.Title asChild>
                  <S.H4>Skapa ny client</S.H4>
                </Dialog.Title>

                <S.CloseButton asChild onClick={(e) => {
                  e.preventDefault();
                  setClientName('');
                  setNewClient(false)
                }}>
                  <S.Icon src="Close" width={20} height={20} />
                </S.CloseButton>
              </S.ContentHeader>

              <form onSubmit={() => {
                createClient()
              }}>
                <S.Margin $vertical='m' />
                <Input label="Namn" value={clientName} onChange={(e) => {
                  setClientName(e.target.value);
                }} />
                <S.ContentFooter>
                  <Dialog.Close asChild onClick={() => setNewClient(false)}>
                    <S.Button>Avbryt</S.Button>
                  </Dialog.Close>

                  <S.Button type="submit">Spara</S.Button>
                </S.ContentFooter>
              </form>
            </S.Content>
          </S.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
      <S.Margin $top="m" />
      <S.Clients>
        <ClientsTable
          page={page}
          setPage={setPage}
          clients={clients.data}
          numberOfPages={Math.ceil(clients.count / clientsPerPage)}
        />
      </S.Clients>
    </S.Container>
  );
};
