import React, { useState } from 'react';
import { S } from './ControlLayout.styles';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useApi } from '../../../../traits/api/useApi';
import { toast } from '../../../../components/Toast';
import { Error } from '../../../../components/Error';
import { Loading } from '../../../../components/Loading';
import { Spinner } from '../../../../components/Spinner';
import { stringifyDates } from '../../../../traits/utils/stringifyDates';
import { FindOneControlResponse } from '@ansvar-sakerhet/api-client';
import { useMarkReportAsStarted } from './hooks/useMarkReportAsStarted';
import { useGetControl } from '../../../../traits/api/controls/useGetControl';
import { usePutControl } from '../../../../traits/api/controls/usePutControl';
import { useDeleteFile } from '../../../../traits/api/controls/useDeleteFile';
import { usePutCompany } from '../../../../traits/api/controls/usePutCompany';
import { useSummaryForm } from './hooks/useSummaryForm';
import { useFlyleafForm } from './hooks/useFlyleafForm';
import { useFreeTextForm } from './hooks/useFreeTextForm';
import { useResultForm } from './hooks/useResultForm';
import { useCompaniesForm } from './hooks/useCompaniesForm';
import { saveAs } from 'file-saver';
import slugify from 'slugify';
import { useContactDetailsForm } from './hooks/useContactDetailsForm';
import { useGetCompanies } from '../../../../traits/api/controls/useGetCompanies';
import { archiveControl } from '../../../../traits/api/controls/archiveControl';

export const ControlLayout: React.FC = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { inspectionId, state } = useParams();
  const { pathname } = useLocation();
  const getApi = useApi();
  const navigate = useNavigate();
  const control = useGetControl(inspectionId);
  const companies = useGetCompanies(inspectionId);
  const flyleafForm = useFlyleafForm(inspectionId);
  const contactDetailsForm = useContactDetailsForm(inspectionId);
  const summaryForm = useSummaryForm(inspectionId);
  const freeTextForm = useFreeTextForm(inspectionId);
  const companiesForm = useCompaniesForm(inspectionId);
  const resultForm = useResultForm(inspectionId);
  const deleteFile = useDeleteFile(inspectionId);
  const updateCompany = usePutCompany(inspectionId);
  const updateControl = usePutControl();
  useMarkReportAsStarted(inspectionId);
  const handleSaveDraft = async (successToast = true) => {
    if (!control.data) return;

    const errors: string[] = [];

    if (flyleafForm.formState.isDirty) {
      const isValid = await flyleafForm.trigger();
      if (!isValid) {
        errors.push('Försättsbladet är inte korrekt ifyllt');
      }
    }

    if (summaryForm.formState.isDirty) {
      const isValid = await summaryForm.trigger();
      if (!isValid) {
        errors.push('Resultat och samlad bedömning är inte korrekt ifylld');
      }
    }

    if (freeTextForm.formState.isDirty) {
      const isValid = await freeTextForm.trigger();
      if (!isValid) {
        errors.push('Uppdragsbeskrivning är inte korrekt ifylld');
      }
    }

    if (resultForm.formState.isDirty) {
      const isValid = await resultForm.trigger();
      if (!isValid) {
        errors.push('Resultat och åtgärder är inte korrekt ifylld');
      }
    }

    if (errors.length) {
      errors.forEach((error) => toast.error(error));
      return;
    }

    const flyleafData = stringifyDates(flyleafForm.getValues());
    const freeTextData = freeTextForm.getValues();
    const summaryData = summaryForm.getValues();
    const companiesData = companiesForm.getValues();

    const contactDetailsData = contactDetailsForm.getValues();
    const { deletedFiles, ...resultData } = resultForm.getApiFriendlyValues();

    if (deletedFiles.length) {
      await Promise.all(
        deletedFiles.map((controlFileId) =>
          deleteFile.mutateAsync({ controlFileId })
        )
      );
    }

    if (companiesData.companies.length) {
      await Promise.all(
        companiesData.companies.map((company) =>
          updateCompany.mutateAsync({
            ...company,
            nationality: company.nationality ?? undefined,
          })
        )
      );
    }

    await updateControl.mutateAsync({
      controlId: control.data.controlId,
      ...flyleafData,
      ...freeTextData,
      ...summaryData,
      ...resultData,
      ...contactDetailsData,
    });

    if (successToast) {
      toast.success('Utkast sparat');
    }
  };

  const generateReportPdf = async (id06: boolean) => {
    if (!control.data || isDownloading) return;
    const api = await getApi();
    setIsDownloading(true);

    try {
      toast.info('Skapar rapport...');

      await handleSaveDraft(false);

      const { client, project, inspectionId, controlId } = control.data;
      const response = await api.controlsGenerateReport(controlId, id06, {
        responseType: 'blob',
      });

      const name = `${client.name} ${project.name} ${inspectionId}`;
      const filename = `${slugify(name)}.pdf`;

      const blob = new Blob([response.data as unknown as BlobPart], {
        type: 'application/pdf',
      });

      saveAs(blob, filename);

      toast.success('Rapport skapad');
    } catch (error) {
      toast.error('Kunde inte skapa rapport');
    } finally {
      setIsDownloading(false);
    }
  };

  const isTabActive = (tab: string) => {
    return pathname.includes(tab);
  };

  const navigateToControls = () => {
    navigate(`/kontroller/${state}`);
  };

  const navigateToContactInformation = () => {
    navigate(`/kontroller/${state}/${inspectionId}/kontaktuppgifter`);
  };

  const navigateToPersonalData = () => {
    navigate(`/kontroller/${state}/${inspectionId}/persondata`);
  };

  const navigateToCompaniesData = () => {
    navigate(`/kontroller/${state}/${inspectionId}/underentreprenorer`);
  };

  const navigateToCoverPage = () => {
    navigate(`/kontroller/${state}/${inspectionId}/forsattsblad`);
  };

  const navigateToDescription = () => {
    navigate(`/kontroller/${state}/${inspectionId}/uppdragsbeskrivning`);
  };

  const navigateToAssessment = () => {
    navigate(`/kontroller/${state}/${inspectionId}/bedomning`);
  };

  const navigateToResult = () => {
    navigate(`/kontroller/${state}/${inspectionId}/resultat`);
  };

  const navigateToDeviations = () => {
    navigate(`/kontroller/${state}/${inspectionId}/avvikelser`);
  };

  const archiveControlD = archiveControl();

  const archive = () => {
    if(control.data){
      archiveControlD.mutate(control.data.controlId)
    }
  }

  if (control.isLoading) return <Loading />;

  if (control.isError) return <Error refetch={control.refetch} />;

  const noWorkRegister =
    !control.data.workRegister && !control.data.gdprDataDeletedAt;

  return (
    <React.Fragment>
      <S.ControlHeaderContainer>
        <S.ControlHeaderInner>
          <S.Flex $height={100} $justify="between">
            <S.ControlInfoContainer>
              <S.ControlBackButtonWrapper onClick={navigateToControls}>
                <S.Icon src="ChevronLeft" width={36} height={36} />
              </S.ControlBackButtonWrapper>

              <S.H3 $top="xs">
                {control.data.client.name} / {control.data.project.name}
              </S.H3>

              <S.H5 $weight={300}>{control.data.inspectionId}</S.H5>
            </S.ControlInfoContainer>

            <S.ControlActionsContainer>
              <S.Button $outlined onClick={() => handleSaveDraft()}>
                Spara 
              </S.Button>

              <S.Button
                disabled={noWorkRegister || isDownloading}
                onClick={() => generateReportPdf(false)}
              >
                {isDownloading ? <Spinner /> : 'Skapa'}
              </S.Button>
              <S.Button
                disabled={noWorkRegister || isDownloading}
                onClick={() => generateReportPdf(true)}
              >
                {isDownloading ? <Spinner /> : 'Utan ID06'}
              </S.Button>
              <S.Button onClick={() => archive()}>
                Arkivera
              </S.Button>
            </S.ControlActionsContainer>
            
          </S.Flex>

          <S.ControlNavigationContainer>
            <S.NavLink
              $isActive={isTabActive('kontaktuppgifter')}
              $isDisabled={noWorkRegister}
              onClick={navigateToContactInformation}
            >
              Kontaktuppgifter
            </S.NavLink>

            <S.NavLink
              $isActive={isTabActive('persondata')}
              $isDisabled={noWorkRegister}
              onClick={navigateToPersonalData}
            >
              Persondata
            </S.NavLink>

            <S.NavLink
              $isActive={isTabActive('underentreprenorer')}
              $isDisabled={noWorkRegister}
              onClick={navigateToCompaniesData}
            >
              Underentreprenörer
            </S.NavLink>

            <S.NavLink
              $isActive={isTabActive('forsattsblad')}
              $isDisabled={noWorkRegister}
              onClick={navigateToCoverPage}
            >
              Försättsblad
            </S.NavLink>

            <S.NavLink
              $isActive={isTabActive('uppdragsbeskrivning')}
              $isDisabled={noWorkRegister}
              onClick={navigateToDescription}
            >
              Uppdragsbeskrivning
            </S.NavLink>

            <S.NavLink
              $isActive={isTabActive('bedomning')}
              $isDisabled={noWorkRegister}
              onClick={navigateToAssessment}
            >
              Resultat och samlad bedömning
            </S.NavLink>

            <S.NavLink
              $isActive={isTabActive('resultat')}
              $isDisabled={noWorkRegister}
              onClick={navigateToResult}
            >
              Resultat/åtgärder
            </S.NavLink>

            <S.NavLink
              $isActive={isTabActive('avvikelser')}
              $isDisabled={noWorkRegister}
              onClick={navigateToDeviations}
            >
              Avvikelser personkontroll
            </S.NavLink>
          </S.ControlNavigationContainer>
        </S.ControlHeaderInner>
      </S.ControlHeaderContainer>

      <S.ControlBodyContainer>
        <S.ControlBodyInner>
          <Outlet
            context={{
              control,
              companies,
              flyleafForm,
              summaryForm,
              freeTextForm,
              resultForm,
              companiesForm,
              contactDetailsForm,
            }}
          />
        </S.ControlBodyInner>
      </S.ControlBodyContainer>
    </React.Fragment>
  );
};

type OutletContext = {
  control: { data: FindOneControlResponse };
  companies: ReturnType<typeof useGetCompanies>;
  flyleafForm: ReturnType<typeof useFlyleafForm>;
  summaryForm: ReturnType<typeof useSummaryForm>;
  freeTextForm: ReturnType<typeof useFreeTextForm>;
  resultForm: ReturnType<typeof useResultForm>;
  companiesForm: ReturnType<typeof useCompaniesForm>;
  contactDetailsForm: ReturnType<typeof useContactDetailsForm>;
};

export const useContactDetailsContext = () => {
  return useOutletContext<OutletContext>().contactDetailsForm;
};

export const useControl = () => {
  return useOutletContext<OutletContext>().control;
};

export const useCompanies = () => {
  return useOutletContext<OutletContext>().companies;
};

export const useFlyleafFormContext = () => {
  return useOutletContext<OutletContext>().flyleafForm;
};

export const useSummaryFormContext = () => {
  return useOutletContext<OutletContext>().summaryForm;
};

export const useFreeTextFormContext = () => {
  return useOutletContext<OutletContext>().freeTextForm;
};

export const useResultFormContext = () => {
  return useOutletContext<OutletContext>().resultForm;
};

export const useCompaniesFormContext = () => {
  return useOutletContext<OutletContext>().companiesForm;
};
