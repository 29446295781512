import React, { useMemo, useState } from 'react';
//import { FrameCompaniesTable } from './components/SubContractorsTable';
import { Loading } from '../../../../../../../../components/Loading';
// import { Pagination } from '../../../../../../../../components/Pagination';
import { Margin } from '@ansvar-sakerhet/ui/src/layout/Margin';
import { SubcontractorsTable } from './components/SubcontractorsTable/SubcontractorsTable';
import { useGetAllCompanies } from '../../../../../../../../traits/api/companies/useGetAllCompanies';

type Props = {
  clientId: string;
};

export const SubContractorsList = ({ clientId }: Props) => {
  const [page] = useState(0);
  const entitiesPerPage = 10;
  const pagination = useMemo(
    () => ({
      skip: entitiesPerPage * page,
      take: entitiesPerPage,
    }),
    [page]
  );

  const {
    data: companies,
    isLoading: companiesLoading,
  } = useGetAllCompanies(clientId, pagination.take, pagination.skip);

  if (companiesLoading || !companies) {
    return <Loading />;
  }

  return (
    <>
      <SubcontractorsTable companies={companies} clientId={clientId} />
      <Margin $top="m" />
      {/* <Pagination
        numberOfPages={Math.ceil(count / entitiesPerPage)}
        page={page}
        setPage={setPage}
      /> */}
    </>
  );
};
