import { FindAllRedListedCompaniesResponse } from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';
import { RedListedCompaniesSortControls } from '../../../traits/api/redListedCompanies/useRedListedCompanies';
import { TableProps } from '../types';

interface RedListedCompaniesHook {
  redListedCompanies: FindAllRedListedCompaniesResponse[];
}

export function useRedListedCompaniesHook({
  redListedCompanies,
}: RedListedCompaniesHook) {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Bolag',
        accessor: 'name',
        onClick: (
          setSort: TableProps['setSort'],
          currentSort: RedListedCompaniesSortControls
        ) => {
          if (currentSort === RedListedCompaniesSortControls.CompanyNameAsc) {
            return setSort(RedListedCompaniesSortControls.CompanyNameDesc);
          }

          return setSort(RedListedCompaniesSortControls.CompanyNameAsc);
        },
        iconSrc: (currentSort: RedListedCompaniesSortControls) =>
          currentSort === RedListedCompaniesSortControls.CompanyNameAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Org.nr',
        accessor: 'registrationNumber',
        onClick: (
          setSort: TableProps['setSort'],
          currentSort: RedListedCompaniesSortControls
        ) => {
          if (
            currentSort === RedListedCompaniesSortControls.RegistrationNumberAsc
          ) {
            return setSort(
              RedListedCompaniesSortControls.RegistrationNumberDesc
            );
          }

          return setSort(RedListedCompaniesSortControls.RegistrationNumberAsc);
        },
        iconSrc: (currentSort: RedListedCompaniesSortControls) =>
          currentSort === RedListedCompaniesSortControls.RegistrationNumberAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Datum',
        accessor: 'redlistedAt',
        onClick: (
          setSort: TableProps['setSort'],
          currentSort: RedListedCompaniesSortControls
        ) => {
          if (currentSort === RedListedCompaniesSortControls.DateAsc) {
            return setSort(RedListedCompaniesSortControls.DateDesc);
          }

          return setSort(RedListedCompaniesSortControls.DateAsc);
        },
        iconSrc: (currentSort: RedListedCompaniesSortControls) =>
          currentSort === RedListedCompaniesSortControls.DateAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },

      {
        Header: 'Åtgärder',
        accessor: 'actions',
          iconSrc: (currentSort: RedListedCompaniesSortControls) =>
          currentSort === RedListedCompaniesSortControls.DateAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
            onClick: (
          setSort: TableProps['setSort'],
          currentSort: RedListedCompaniesSortControls
        ) => {
          if (currentSort === RedListedCompaniesSortControls.DateAsc) {
            return setSort(RedListedCompaniesSortControls.DateDesc);
          }

          return setSort(RedListedCompaniesSortControls.DateAsc);
        },
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return redListedCompanies.map((company) => {
      const date = new Date(company?.createdAt || '');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();

      return {
        name: company.name,
        registrationNumber: company.registrationNumber,
        redlistedAt: `${month}/${day}/${year}`,
        actions: 'actions',
      };
    });
  }, [redListedCompanies]);

  return { columns, data };
}
