import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const addCustomerUserForm = z.object({
  email: z.string().email('E-post ej giltig'),
  password: z.string().min(8, 'Lösenord måste vara minst 8 karaktärer'),
  firstName: z.string().min(1, 'Namn måste anges'),
  lastName: z.string().min(1, 'Efternamn måste anges'),
  clientId: z.string().min(1, 'Kund måste väljas'),
  hidden: z.boolean(),
});

export type addCustomerUserFormValues = z.infer<typeof addCustomerUserForm>;

export const useAddCustomerUserForm = ({
  defaultClientId,
}: {
  defaultClientId: string;
}) => {
  const methods = useForm<addCustomerUserFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(addCustomerUserForm),
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      clientId: defaultClientId,
      hidden: false,
    },
  });

  return methods;
};
