import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { DeleteCustomerUserDto } from '@ansvar-sakerhet/api-client';
import { toast } from '../../../components/Toast';
import { AxiosError } from 'axios';
import { KNOWN_ERRORS } from './createCustomerUserKnownErrors.constant';

export function useDeleteCustomerUser() {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const mutationFn = async (data: DeleteCustomerUserDto) => {
    const api = await getApi();

    const res = await api.customerUserDeleteCustomerUser(data);
    await queryClient.invalidateQueries();

    return res.data;
  };

  const query = useMutation({
    mutationFn,
    onSuccess: () => toast.success('Kund-användare borttagen'),
    onError: (e) => toast.error(extractError(e)),
  });

  return {
    ...query,
  };
}

function extractError(e: unknown) {
  if (e instanceof AxiosError) {
    return translateErrorMsg(e.response?.data?.message);
  }
  return 'Okänt fel';
}

function translateErrorMsg(msg: string) {
  switch (msg) {
    case KNOWN_ERRORS.USER_EXISTS:
      return 'En användare använder redan denna e-post. Vänligen ange en annan e-post';
    case KNOWN_ERRORS.PASSWORD_TOO_WEAK:
      return 'Lösenordet är för svagt, vänligen ange ett starkare lösenord';
    case KNOWN_ERRORS.EMAIL_INVALID:
      return 'E-post är ogiltig';
    case KNOWN_ERRORS.FIRST_NAME_MUST_EXIST:
      return 'Namn måste anges';
    case KNOWN_ERRORS.LAST_NAME_MUST_EXIST:
      return 'Efternamn måste anges';
    case KNOWN_ERRORS.NO_CLIENTS_SPECIFIED:
      return 'Kund måste väljas';
    case KNOWN_ERRORS.PASSWORD_TOO_SHORT:
      return 'Lösenordet är för kort';
    default:
      return msg;
  }
}
