import React from 'react';
import { FindAllCompaniesResponse } from '@ansvar-sakerhet/api-client';
import { useTable } from 'react-table';
import { S } from './SubcontractorsTable.style';
import { useCompaniesTableData } from './hooks/useCompaniesTableData';
import * as Dialog from '@radix-ui/react-dialog';
import { useDeleteBackgroundControl } from '../../../../../../../../../../traits/api/backgroundControls/useDeleteBackgroundControl';
import { CreateBGControl } from './components/CreateBGControl/CreateBGControl';
import { useDeleteCompany } from '../../../../../../../../../../traits/api/companies/deleteCompany';


export const SubcontractorsTable = ({
  companies,
  clientId,
}: {
  companies: FindAllCompaniesResponse[];
  clientId: string;
}) => {
  const data = useCompaniesTableData(companies);
  const table = useTable(data);
  const [open, setOpen] = React.useState<null | string>();
  const [addOpen, setAddOpen] = React.useState(false);
  const [projectId, setProjectId] = React.useState<null | string>(null);
  const [companyReg, setCompanyReg] = React.useState<null | string>(null);
  const deleteBgControl = useDeleteBackgroundControl();

  const deleteComp = useDeleteCompany();

  const deleteCompany = () => {
    if(open){
      deleteComp.mutate({ companyId: companies[Number(open)].companyId });
      setOpen(null);
    }
  }

  return (
    <>
      <S.Button onClick={() => setAddOpen(true)}>
        <S.Icon src="Plus" /> Skapa bakgrundskontroll
      </S.Button>
      <CreateBGControl open={addOpen} onCancel={() => setAddOpen(false)} clientId={clientId} projectId={projectId} companyReg={companyReg} />

      <S.TableContainer>
        <S.AltTable {...table.getTableProps()}>
          <S.AltTHead>
            {table.headerGroups.map((headerGroup) => (
              <S.AltTHeadRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <S.AltTHeadCell
                      {...column.getHeaderProps()}
                      key={column.getHeaderProps().key}
                    >
                      <S.TableText>{column.render('Header')}</S.TableText>
                    </S.AltTHeadCell>
                  );
                })}
              </S.AltTHeadRow>
            ))}
          </S.AltTHead>

          <S.AltTBody {...table.getTableBodyProps()}>
            {table.rows.map((row) => {
              table.prepareRow(row);

              return (
                <>
                  <S.AltTBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                    {row.cells.map((cell) => {
                      if (cell.column.id === 'name') {
                        return (
                          <>
                            <S.AltTBodyCell
                              onClick={() => {
                                if (open === row.id) {
                                  setOpen(null);
                                } else {
                                  setOpen(row.id);
                                }
                              }}
                              {...cell.getCellProps()}
                              key={cell.getCellProps().key}
                            >
                              {cell.render('Cell')}
                            </S.AltTBodyCell>

                          </>
                        );


                      }
                      return (
                        <S.AltTBodyCell
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                        >
                          {cell.render('Cell')}
                        </S.AltTBodyCell>
                      );
                    })}
                  </S.AltTBodyRow>
                </>
              );
            })}
          </S.AltTBody>
        </S.AltTable>
      </S.TableContainer>
      <Dialog.Root open={open !== undefined && open !== null}>
        <Dialog.Portal>
          <S.Overlay>
            <S.Content>
              <S.ContentHeader>
                <Dialog.Title asChild>
                  <S.H4>Tidigare bakgrundskontroller</S.H4>
                </Dialog.Title>

                <S.CloseButton onClick={() => setOpen(null)}>
                  <S.Icon src="Close" width={20} height={20} />
                </S.CloseButton>
              </S.ContentHeader>
              {
                open && companies[Number(open)] ? companies[Number(open)].backgroundControls.map((control) => {
                  return (
                    <S.Flex key={control.backgroundControlId} style={{ marginTop: 20 }}>
                      <S.B1>{(new Date(control.createdAt).toISOString().split('T')[0])}</S.B1>
                      <S.CloseButton style={{ marginLeft: 20 }} onClick={() => deleteBgControl.mutate(control.backgroundControlId)}>
                        <S.Icon src="Close" width={10} height={10} />
                      </S.CloseButton>
                    </S.Flex>
                  );
                }) : null
              }

              <S.Margin $vertical='xl' />
              <S.Button onClick={() => deleteCompany()}>
                Ta bort bolag
              </S.Button>
                <S.Margin $vertical='s' />
              <S.Button onClick={() => {
                setProjectId(companies[Number(open)].projectId);
                setCompanyReg(companies[Number(open)].registrationNumber);
                setOpen(null);
                setAddOpen(true);
              }}>
                Skapa bakgrundskontroll
              </S.Button>
            </S.Content>
          </S.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
