import React from 'react';
import { useTable } from 'react-table';
import { S } from '../RedListedCompanies.styled';
import { useRedListedCompaniesHook } from '../hooks/useRedListedCompaniesHook';
import { TableProps } from '../types';
import { useDeleteRedListed } from '../../../traits/api/redListedCompanies/deleteRedListedCompany';

export const Table: React.FC<TableProps> = ({ companies, setSort, sort }) => {
  const data = useRedListedCompaniesHook({ redListedCompanies: companies });
  const table = useTable(data);

  const deleteRedlisted = useDeleteRedListed();

  if (data?.data?.length === 0) {
    return null;
  }

  return (
    <S.TableContainer>
      <S.AltTable {...table.getTableProps()}>
        <S.AltTHead>
          {table.headerGroups.map((headerGroup) => (
            <S.AltTHeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                const headerData = data.columns.filter(
                  ({ accessor }) => accessor === column.id
                )[0];
                const interactiveProps = headerData?.onClick
                  ? { onClick: () => headerData?.onClick(setSort, sort) }
                  : {};
                const iconSrc = headerData?.iconSrc?.(sort);

                return (
                  <S.AltTHeadCell
                    {...column.getHeaderProps()}
                    {...interactiveProps}
                    key={column.getHeaderProps().key}
                  >
                    <S.TableText>{column.render('Header')}</S.TableText>
                    {iconSrc && <S.CenteredIcon src={iconSrc} />}
                  </S.AltTHeadCell>
                );
              })}
            </S.AltTHeadRow>
          ))}
        </S.AltTHead>

        <S.AltTBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.AltTBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  if(cell.column.id === 'actions'){
                    return (
                      <S.AltTBodyCell
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        <S.Button onClick={() => 
                          deleteRedlisted.mutateAsync(companies[row.index].redlistedCompanyId)
                        }>
                          Delete
                        </S.Button>
                      </S.AltTBodyCell>
                    );
                  }
                  return (
                    <S.AltTBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.AltTBodyCell>
                  );
                })}
              </S.AltTBodyRow>
            );
          })}
        </S.AltTBody>
      </S.AltTable>
    </S.TableContainer>
  );
};
