import React from 'react';
import { S } from './DangerZone.styles';
import { useDeleteClient } from '../../../../../../../../traits/api/clients/deleteClient';
import { useNavigate } from 'react-router-dom';


type Props = {
  clientId: string;
};


export const DangerZone = ({clientId}: Props) => {

    const deleteClient = useDeleteClient();
    const navigate = useNavigate();

    return (
        <S.Flex $flexDir="column" >
        <S.B1 $weight={700}>VARNING</S.B1>
        <S.Margin $top="s" />
        <S.B2>
            Om du tar bort kunden kommer all data att raderas och kan inte återställas.
        </S.B2>
        <S.Margin $top="s" />
        <S.B2>
            Innan du tar bort kunden behöver du ta bort alla användare.
        </S.B2>
        <S.Margin $top="m" />
        <S.Button onClick={() => {
            deleteClient.mutate(clientId);

            navigate('/kunder');
        }}>Ta bort kund</S.Button>
        </S.Flex>
    );
}