import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { AxiosError } from 'axios';

export function useDeleteBackgroundControl() {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const mutationFn = async (data: string) => {
    const api = await getApi();

    const res = await api.backgroundControlsDelete(data);
    await queryClient.invalidateQueries();

    return res.data;
  };

  const query = useMutation({
    mutationFn,
    onSuccess: () => toast.success('Bakgrundskontroll raderad'),
    onError: (e) => toast.error(extractError(e)),
  });

  return {
    ...query,
  };
}

function extractError(e: unknown) {
  if (e instanceof AxiosError) {
    return e.response?.data?.message;
  }
  return 'Okänt fel';
}
