import React from 'react';
import { S } from './AddRedListedCompanyForm.styles';
import { Button } from '@ansvar-sakerhet/ui/src/buttons/Button';
import { Input } from '../../../../components/Input';
import {
  addCustomerUserFormValues,
  useAddRedListedCompanyForm,
} from './hooks/useAddRedListedCompanyForm';
import { useCreateRedListedCompany } from '../../../../traits/api/redListedCompanies/useCreateRedListedCompany';

export const AddRedListedCompanyForm = ({
  onCancel,
}: {
  onCancel: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useAddRedListedCompanyForm();
  const { mutateAsync } = useCreateRedListedCompany();

  const onSubmit = async (data: addCustomerUserFormValues) => {
    await mutateAsync({
      name: data.name,
      registrationNumber: data.registrationNumber,
      date: data.date,
    });
    

    onCancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Container>
        <Input label="Namn" error={errors.name} {...register('name')} />
        <Input
          label="Org.nr"
          error={errors.registrationNumber}
          {...register('registrationNumber')}
        />

        <Input
          type='date'
          label="Datum"
          error={errors.date}
          {...register('date')}
        />

        <S.Flex $justify="between" $gap={16} $flexDir="row">
          <Button $outlined type="button" onClick={onCancel}>
            Avbryt
          </Button>
          <Button type="submit">Spara</Button>
        </S.Flex>
      </S.Container>
    </form>
  );
};
