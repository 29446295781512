import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './company.key';

export function useGetAllCompanies(
  clientId: string,
  take: number,
  skip: number
) {
  const getApi = useApi();

  const getCompanies = async () => {
    const api = await getApi();
    const res = await api.companiesFindAllForClient(clientId, take, skip);
    return res;
  };

  const query = useQuery({
    queryKey: keys.findAll(take, skip),
    queryFn: getCompanies,
  });

  return {
    ...query,
    data: query.data?.data,
    count: query.data?.headers['x-total-count'],
  };
}
